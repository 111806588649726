import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SocialAuthServiceConfig, SocialLoginModule } from "angularx-social-login";
import { AppleLoginProvider } from "./pages/auth/social-login/apple.provider";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CountdownModule } from "ngx-countdown";
import { CookieModule } from "ngx-cookie";
import { environment } from "src/environments/environment";
import { enGbLocale, defineLocale } from 'ngx-bootstrap/chronos';
import { CalenderLocalization, enUsCustomLocale } from "./shared/constant/generic.const";

defineLocale(CalenderLocalization.UK, enGbLocale); // Monday as first day
defineLocale(CalenderLocalization.US, enUsCustomLocale); // Sunday as first day
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
		SocialLoginModule,
    NgbModule,
    CountdownModule,
    CookieModule.forRoot()
  ],

  providers: [
    {
		  provide: "SocialAuthServiceConfig",
		  useValue: {
			autoLogin: false,
			providers: [
			  {
				id: AppleLoginProvider.PROVIDER_ID,
				provider: new AppleLoginProvider(environment.clientId),
			  },
			],
		  } as SocialAuthServiceConfig,
		},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
